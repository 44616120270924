 import { environment  } from '../../environments/environment';

export const HttpRequestConstants = {
  AUTH_API: environment.apiURL,
  GET_POST_PUT_DELETE_AIRPORTS: environment.apiURL + 'airports',
  GET_POST_PUT_DELETE_USERS: environment.apiURL + 'users',
  GET_POST_PUT_DELETE_USERS_ACTIVATE: environment.apiURL + 'users/activate',
  GET_POST_PUT_DELETE_USERS_SUSPEND: environment.apiURL + 'users/suspend',
  GET_POST_PUT_DELETE_USERS_RESET_PASSWORD: environment.apiURL + 'users/resetPassword',
  GET_POST_PUT_DELETE_USERS_EXPORT: environment.apiURL + 'users/export',

  GET_POST_PUT_DELETE_BRANCHES: environment.apiURL + 'branches',
  GET_POST_PUT_DELETE_CREW_TRANSPORTATIONS: environment.apiURL + 'crewTransportations',

  GET_POST_PUT_DELETE_FEEDBACK_TOPICS: environment.apiURL + 'feedbackTopics',
  GET_POST_PUT_DELETE_FEEDBACK_TOPIC_GROUPS: environment.apiURL + 'feedbackTopicGroups',
  GET_POST_PUT_DELETE_SCORE_CARD_GROUPS: environment.apiURL + 'scoreCardGroups',

  GET_POST_PUT_DELETE_SUPPORT_REQUESTS: environment.apiURL + 'supportRequests',
  GET_POST_PUT_DELETE_SUPPORT_RESPONSES: environment.apiURL + 'supportResponses',

  GET_POST_PUT_DELETE_ROLES: environment.apiURL + 'roles',

  GET_POST_PUT_DELETE_COUNTRIES: environment.apiURL + 'countries',
  GET_POST_PUT_DELETE_AIRLINES: environment.apiURL + 'airlines',
  
  GET_POST_PUT_DELETE_AIRLINES_BY_ENTERDOMAINS: environment.apiURL + 'airlinesByEnterDomains',
  GET_POST_PUT_DELETE_REGISTER_USER: environment.apiURL + 'register',
  GET_RESET_PASSWORD :  environment.apiURL + 'resetPassword',
  
  GET_LAYOVER_DATA: environment.apiURL + 'layoverData',
  GET_POST_PUT_DELETE_FEEDBACKS: environment.apiURL + 'feedbacks',
  GET_POST_PUT_DELETE_FEEDBACK_COMMENTS: environment.apiURL + 'feedbackComments',

  GET_POST_PUT_DELETE_TRANSPORT_COMPANIES: environment.apiURL + 'transportCompanies',
  GET_POST_PUT_DELETE_TRANSPORT_COMPANIES_EXPORT: environment.apiURL + 'transportCompanies/export',

  GET_POST_PUT_DELETE_HOTELS: environment.apiURL + 'hotels',
  GET_POST_PUT_DELETE_DAILY_INCIDENT_LOGS: environment.apiURL + 'dailyIncidentLogs',
  GET_POST_PUT_DELETE_FEATURED_HOTELS_AND_CITIES: environment.apiURL + 'featuredHotels',

  GET_POST_PUT_DELETE_TAXONOMIES: environment.apiURL + 'taxonomies',
  GET_POST_PUT_DELETE_WEATHER: environment.apiURL + '5DayWeatherForecast',

  GET_POST_PUT_DELETE_INAPPRATINGS: environment.apiURL + 'inAppRatings',
  GET_POST_PUT_DELETE_INAPPRATINGS_SUMMARY: environment.apiURL + 'inAppRatingsSummary',

  GET_POST_PUT_DELETE_EVENTS: environment.apiURL + 'events',
  GET_POST_PUT_DELETE_EVENT_RATINGS: environment.apiURL + 'eventRatings',

  GET_POST_PUT_DELETE_USER_ACTIVITY_HISTORY: environment.apiURL + 'userActivityHistory',

  GET_POST_PUT_DELETE_SUBMIT_RESEND_VOUCHER: environment.apiURL + 'submitResendVoucher',
  GET_POST_PUT_DELETE_SUBMIT_HOTEL_NOT_NEEDED: environment.apiURL + 'submitHotelNotNeeded',

  GET_POST_PUT_DELETE_PINGONE_LOGIN: environment.apiURL + 'pingOneLogin',

  GET_POST_PUT_DELETE_MY_TRAVEL_DEMO: environment.apiURL + 'myTravelDemos',
  GET_POST_PUT_DELETE_EMAIL_TEMPLATES: environment.apiURL + 'emailTemplates',
  GET_POST_PUT_DELETE_EMAIL_TYPES: environment.apiURL + 'emailTypes',

  GET_POST_PUT_DELETE_CREW_CALENDAR: environment.apiURL + 'crewCalendars',

  GET_POST_PUT_DELETE_CONFIG: environment.apiURL + 'config',
  GET_POST_PUT_DELETE_HOTEL_STAR_RATING: environment.apiURL + 'hotelStarRatings',
  GET_POST_PUT_DELETE_GOOGLE_PLACES: environment.apiURL + 'googlePlaces',
  

  
};
