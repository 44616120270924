import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class HttpRequestService {
  httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
    time: new Date()
  };
  sourcings = new BehaviorSubject('');

  constructor(private http: HttpClient) { }
  
  get(url: any): Observable<any> {
    return this.http.get(url, this.httpOptions);
  }
  getWithExplicitParams(url: any, params: any): Observable<any> {
    return this.http.get(url, { params: params});
  }

  getWithExplicitHttpOption(url: any, httpOptions: any): Observable<any> {
    return this.http.get(url, httpOptions);
  }

  post(url: any, postData: any): Observable<any> {
    return this.http.post(url, postData, this.httpOptions);
  }

  put(url: any, postData: any): Observable<any> {
    return this.http.put(url, postData, this.httpOptions);
  }
  
  putWithOptions(url: any, postData: any, options: any): Observable<any> {
    return this.http.put(url, postData, options);
  }

  postWithOptions(url: any, postData: any, options: any): Observable<any> {
    return this.http.post(url, postData, options);
  }

  delete(url: any): Observable<any> {
    return this.http.delete(url, this.httpOptions);
  }

  patch(url: any, postData: any): Observable<any> {
    return this.http.patch(url, postData, this.httpOptions);
  }

  download(url: any, params: any): Observable<any> {
    return this.http.get(url, { params: params, responseType: 'blob'});
  }
}
