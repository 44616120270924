import { Component, Input } from '@angular/core';
import { CommonModule } from '@angular/common';
import { NzSpinComponent } from 'ng-zorro-antd/spin';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  selector: 'app-page-loading',
  standalone: true,
  imports: [CommonModule,NzSpinComponent, TranslateModule],
  templateUrl: './page-loading.component.html',
  styleUrl: './page-loading.component.scss'
})
export class PageLoadingComponent {
  @Input() loading: boolean = false;

}
