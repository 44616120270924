import { Injectable } from '@angular/core';
import { NzMessageService } from 'ng-zorro-antd/message';
import { catchError, map, Observable, Subject, throwError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { HttpErrorResponse, HttpParams } from '@angular/common/http';

const TOKEN_KEY = 'auth-token';
const USER_KEY = 'auth-user';

@Injectable({
  providedIn: 'root'
})
export class LoginAuthService {
  privateKey = 'mccplus-api';
  errorSubject = new Subject<HttpErrorResponse>();
  constructor(private httpRequestService: HttpRequestService, private message: NzMessageService) { }

  login = (username: string, pass: string) => {
    let encryptedPass = pass;
    const password = encryptedPass.toString();
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'login', { username, password })
      .pipe(map((response) => {
        return response;
      }), catchError((err) => {       
        return throwError(() => err);
      }))
  }

  getSSOUrl = (username: string) => {
    return this.httpRequestService.getWithExplicitParams(HttpRequestConstants.AUTH_API + 'ssoUrl', { username })
      .pipe(map((response) => {
        return response;
      }), catchError((err) => {
        return throwError(() => err);
      }))
  }


  logout = (userId: number): Observable<any> => {
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'logout', { userId });
  }

  refreshToken = (token: string) => {
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'refreshtoken', { refreshToken: token }).pipe(
      catchError(this.handleError));
  }

  sendResetPasswordInstruction = (emailOrUsername: string) => {
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'forgotPassword', { emailOrUsername });
  }

  resetPassword = (pass: any, token: any) => {
    let encryptedPass = pass;
    const password = encryptedPass.toString();
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'resetPassword?' + `token=${token}`, { password });
  }
  changePassword = (pass: any, currentPassword: string) => {
    let encryptedPass = pass;
    const password = encryptedPass.toString();
    return this.httpRequestService.post(HttpRequestConstants.AUTH_API + 'changePassword?', { password, currentPassword });
  }

  handleError(error: any): Observable<any> {
    let errorMessage = '';
    if (error.error instanceof ErrorEvent) {
      errorMessage = `Error: ${error.error.message}`;
    } else {
      if (error.status === 500) {
        this.message.error('');
      }
    }
    return throwError(() => new Error(errorMessage));
  }

  isAuthorized = (allowedRoles: string[]): boolean => {
    const token = sessionStorage.getItem(TOKEN_KEY);
    if (!token) {
      return false;
    }
    if (!allowedRoles || !allowedRoles.length) {
      return true;
    }
    const user = sessionStorage.getItem(USER_KEY);
    if (!user) {
      return false;
    }
    const userRoles = JSON.parse(user).roles;
    const result = userRoles.some((r: any) => allowedRoles.includes(r))
    return result;
  }

  verifyCaptcha = (recaptchaResponse: string) => {
    let params = new HttpParams().set("recaptchaResponse", recaptchaResponse);
    return this.httpRequestService.postWithOptions(HttpRequestConstants.AUTH_API + 'verifyCaptcha', null, {params})
      .pipe(map((response) => {
        return response;
      }), catchError((err) => {    
        return throwError(() => err);
      }))
  }
}
