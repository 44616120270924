export const environment = {
  production: false,
  title: 'Dev Environment',
  appURL: 'https://mccplus-dev.nonprod-apiglobalsolutions.com',  
  apiURL: 'https://mccplus-dev.nonprod-apiglobalsolutions.com/mccplusrest/api/',
  pingOneURL: 'https://auth.pingone.com',
  environmentId: "58569e28-9b65-41ad-a569-2f42f3904c57",
  responseType: "code",
  clientId: "fb262c3f-2dc8-4673-8dee-0b976cf884f9",
  grantType: "authorization_code",
  redirectUri: "/ping/sso/login",
  logoutRedirectUri: "/ping/sso/logout",
  scope: "profile address email phone",
  prompt: "login",
  maxAge: 3600,
  captchaSiteKey: "6LettFoqAAAAAMhovo3qA5AudX1nBvSAynYJVTsC",
  baseHref: 'https://mccplus-dev.nonprod-apiglobalsolutions.com/'
};