import { HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Subject, catchError, map, throwError } from 'rxjs';
import { HttpRequestService } from './http-request.service';
import { HttpRequestConstants } from './http-requests-constants';
import { environment as env } from '../../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class PingOneService {
  error = new Subject<string>();
  constructor(private httpRequestService: HttpRequestService) { }

  authorize = (state: any, nonce: any) => {
    const authUrl = `${env.pingOneURL}/${env.environmentId}/as/authorize?` +
      `client_id=${env.clientId}&` +
      `redirect_uri=${env.appURL}${env.redirectUri}&` +
      `response_type=${env.responseType}` +
      (env.prompt ? `&prompt=${env.prompt}` : '') +
      (env.scope ? `&scope=${env.scope}` : '') +
      (env.maxAge ? `&max_age=${env.maxAge}` : '') +
      (state ? `&state=${state}` : '') +
      (nonce ? `&nonce=${nonce}` : '');
    window.location.replace(authUrl);
  };

  login = (code: string) => {
    let params = new HttpParams().set("code", code);
    return this.httpRequestService.postWithOptions(HttpRequestConstants.GET_POST_PUT_DELETE_PINGONE_LOGIN, null, { params })
      .pipe(
        map(response => {
          return response;
        }),
        catchError(errorRes => {
          return throwError(() => new Error(errorRes));
        })
      )
  };

  logout = (idToken: string) => {
    let authUrl = `${env.pingOneURL}/${env.environmentId}/as/signoff?` +
      `id_token_hint=${idToken}&` +
      `post_logout_redirect_uri=${env.appURL}${env.logoutRedirectUri}`;
    window.location.replace(authUrl);
  };

}

